import React from "react"

/* Import Global Component(s) */
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"

/* Import Local Style(s) */
import "./exhibitor.scss"

const Exhibitor = ({ location }) => {
  return (
    <Page title="Exhibitor" location={location}>
      <PageHeader title={"Exhibitor"} location={location} />
      <h2>Exhibitor</h2>
      <Footer />
    </Page>
  )
}

export default Exhibitor
